var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.displayTitle
        ? _c("PageTitle", {
            staticClass: "mt-5",
            attrs: {
              "previous-location": "",
              "page-title": !_vm.$language ? "Batches" : "ব্যাচ সমূহ",
            },
          })
        : _vm._e(),
      _c("batch-create-c", {
        attrs: { "course-id": _vm.courseId },
        on: { "tab-switch": _vm.tabSwitchHandler },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }