var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "mt-2" },
    [
      _vm.tab == 1
        ? _c(
            "div",
            { staticClass: "mb-5" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2 mb-2",
                  attrs: { color: "black", icon: "", large: "" },
                  on: {
                    click: function ($event) {
                      return _vm.tabSwitch()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("arrow_back")])],
                1
              ),
              _c("span", { staticClass: "fake-title" }, [
                _vm._v(
                  _vm._s(!_vm.$language ? "Batch Create" : "ব্যাচ তৈরী করুন")
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            { staticClass: "clear-background-item" },
            [
              _c("submission-alert-message", {
                ref: "banner",
                staticClass: "py-3",
                staticStyle: { "max-width": "1100px" },
                attrs: {
                  message: _vm.alertMessage,
                  "should-scroll-to-top": "",
                },
                model: {
                  value: _vm.showFormAlertMessage,
                  callback: function ($$v) {
                    _vm.showFormAlertMessage = $$v
                  },
                  expression: "showFormAlertMessage",
                },
              }),
              _c(
                "v-card",
                { staticClass: "m-light-card" },
                [
                  _c("batch-form", {
                    ref: "form",
                    attrs: { "all-valid": _vm.ok },
                    on: {
                      "update:allValid": function ($event) {
                        _vm.ok = $event
                      },
                      "update:all-valid": function ($event) {
                        _vm.ok = $event
                      },
                    },
                    model: {
                      value: _vm.formData,
                      callback: function ($$v) {
                        _vm.formData = $$v
                      },
                      expression: "formData",
                    },
                  }),
                  _c(
                    "v-row",
                    { class: [_vm.formHorizPad, "mt-6"] },
                    [
                      _c("v-col", { staticClass: "d-flex justify-start" }, [
                        _c("h2", { staticClass: "custom-font enroll-header" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                !_vm.$language
                                  ? "Bulk Enrollment"
                                  : "বাল্ক এনরোলমেন্ট"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { class: _vm.formHorizPad },
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex justify-start" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "#0099dc" },
                              on: {
                                click: function ($event) {
                                  return _vm.tabSwitch()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  !_vm.$language
                                    ? "Add student"
                                    : "শিক্ষার্থী যোগ করুন"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-3" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex justify-end pr-5" },
                            [
                              _c(
                                "v-btn",
                                {
                                  class: { "mr-5": _vm.screenSize >= 600 },
                                  attrs: {
                                    depressed: "",
                                    "x-large": _vm.screenSize >= 1600,
                                    large:
                                      _vm.screenSize >= 900 &&
                                      _vm.screenSize < 1600,
                                    small: _vm.screenSize < 600,
                                    outlined: "",
                                    color: "red accented-3",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.replace({
                                        name: "batches",
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm.screenSize >= 600
                                    ? _c("v-icon", [_vm._v("cancel")])
                                    : _vm._e(),
                                  _c("span", { staticClass: "custom-font" }, [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.$language ? "Cancel" : "বাতিল করুন"
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  class: [
                                    "white--text elevation-3",
                                    _vm.screenSize >= 600
                                      ? "px-10 ml-5"
                                      : "ml-2",
                                  ],
                                  attrs: {
                                    "x-large": _vm.screenSize >= 1600,
                                    large:
                                      _vm.screenSize >= 900 &&
                                      _vm.screenSize < 1600,
                                    small: _vm.screenSize < 600,
                                    color: "#0099dd",
                                  },
                                  on: { click: _vm.save },
                                },
                                [
                                  _c("span", { staticClass: "custom-font" }, [
                                    _vm._v(
                                      _vm._s(
                                        !_vm.$language ? "Create" : "তৈরী করুন"
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { staticClass: "clear-background-item" },
            [_c("bulk-enrollment", { on: { bulk_add: _vm.handleBulkAdd } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }