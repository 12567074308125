<template>
  <div v-if="renderNow" class="table">
    <div class="header px-3 elevation-3">
      <v-row no-gutters>
        <v-col cols="2">
          <v-row>
            <v-col class="d-flex align-center justify-center"
              ><span class="content-text">{{
                !$language ? "Avatar" : "ছবি"
              }}</span></v-col
            >
            <v-col v-if="screenSize >= 900" cols="3"></v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex align-center"
          ><span class="content-text">{{
            !$language ? "Name" : "নাম"
          }}</span></v-col
        >
        <v-col class="d-flex align-center"
          ><span class="content-text">{{
            !$language ? "Number" : "মোবাইল নাম্বার"
          }}</span></v-col
        >
        <v-col v-if="screenSize >= 900" class="d-flex align-center"
          ><span class="content-text">{{
            !$language ? "Email" : "ইমেইল"
          }}</span></v-col
        >
        <v-col cols="2">
          <v-row>
            <v-col v-if="screenSize >= 900" cols="2"></v-col>
            <v-col class="d-flex align-center justify-center"
              ><span class="content-text">{{
                !$language ? "Add/Remove" : "সংযোগ/অপসারণ"
              }}</span></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="content px-4 pt-2">
      <v-row
        v-for="(student, index) in studentList"
        :key="index"
        no-gutters
        class="content-item"
      >
        <v-col cols="2">
          <v-row>
            <v-col class="d-flex align-center justify-center">
              <v-avatar
                :color="student.color"
                class="white--text"
                :size="avatarSize"
              >
                <span class="content-text">{{ student.avatar }}</span>
              </v-avatar>
            </v-col>
            <v-col v-if="screenSize >= 900" cols="3"></v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex align-center"
          ><span class="content-text text-truncate">{{
            student.name
          }}</span></v-col
        >
        <v-col class="d-flex align-center"
          ><span class="content-text text-truncate">{{
            student.number
          }}</span></v-col
        >
        <v-col v-if="screenSize >= 900" class="d-flex align-center"
          ><span class="content-text">{{ student.email }}</span></v-col
        >
        <v-col cols="2">
          <v-row>
            <v-col v-if="screenSize >= 900" cols="2"></v-col>
            <v-col class="d-flex align-center justify-center">
              <mk-check-box
                color="#fc6222"
                :active="student.add"
                :index="index"
                :size-object="checkDim"
                min-width="12"
                @click="mark"
              ></mk-check-box>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="controls">
      <transition>
        <v-row class="px-3">
          <v-col class="d-flex justify-end pr-5">
            <v-btn
              v-if="addMode"
              :x-large="screenSize >= 1600"
              :large="screenSize >= 900 && screenSize < 1600"
              :x-small="screenSize < 600"
              outlined
              color="red accented-3"
              :class="[screenSize > 600 ? 'mr-5' : 'mr-2 ', 'rounded-lg']"
              @click="handleCancel"
            >
              <!-- <v-icon>cancel</v-icon> -->
              <span class="custom-font"
                ><v-icon v-if="screenSize > 600">cancel</v-icon
                >{{ !$language ? "Cancel" : "বাতিল করুন" }}</span
              >
            </v-btn>
            <v-btn
              :loading="saving['status']"
              depressed
              :x-large="screenSize >= 1600"
              :large="screenSize >= 900 && screenSize < 1600"
              :x-small="screenSize < 600"
              color="#0099dd"
              :class="[
                'white--text rounded-lg',
                screenSize > 600 ? 'mr-5 px-10' : ''
              ]"
              @click="save"
            >
              <span class="custom-font">{{
                !$language ? "Save Changes" : "সেভ করুন"
              }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </transition>
    </div>
  </div>
</template>

<script>
import MkCheckBox from "./MkCheckBox.vue";

export default {
  name: "BulkEnrollment",
  components: { MkCheckBox },
  inject: ["$courseId", "$batchId"],
  data() {
    return {
      addList: {},
      studentList: [],
      batchStudentsObject: {},
      organizationStudentsObject: {},
      chars: "abcdefghijklmnopqrstuvwxyz",
      selected: 0,
      base: 0,
      globalActive: true,
      forceActive: "",
      forceAvtiveAll: "",
      renderNow: false,
      colors: ["pink", "purple", "indigo", "teal", "amber"],
      saving: {},
      addMode: false
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    editingColor() {
      return this.editing ? "red accented-3" : "amber darken-3";
    },
    selectAllIcon() {
      if (this.selected > 0) {
        return "mdi-minus";
      }
      return "mdi-check";
    },
    checkDim() {
      let dim =
        this.screenSize < 600
          ? 12
          : this.screenSize < 900
          ? 14
          : this.screenSize < 1600
          ? 18
          : 24;
      return { height: dim, width: dim };
    },
    avatarSize() {
      return this.screenSize < 600
        ? 21
        : this.screenSize < 900
        ? 24
        : this.screenSize < 1600
        ? 36
        : 45;
    }
  },
  async created() {
    this.renderNow = false;
    //await this.retrieveBatchStudentInfo();
    await this.retrieveOrganizationStudentInfo();
    this.populateStudentList();
    this.renderNow = true;
  },
  methods: {
    randomColour() {
      let i = Math.round(Math.random() * 100) % 5;
      return this.colors[i];
    },
    mark(index) {
      this.addMode = true;
      if (this.studentList[index].add) {
        this.$delete(this.addList, this.studentList[index].id);
      } else {
        this.$set(this.addList, this.studentList[index].id, true);
      }
      this.studentList[index].add = !this.studentList[index].add;
      this.editing = true;
    },
    async retrieveOrganizationStudentInfo() {
      try {
        this.organizationStudentsObject = await this.$store.dispatch(
          "batch/getOrganizationStudentInformation",
          {
            partner_code: this.$route.params.partner_code,
            course_Id: this.$courseId()
          }
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    avatarInitials(nameStr) {
      let chars = nameStr.split(" ");
      let ret = "";
      for (let c of chars) {
        ret += c[0];
      }
      return ret;
    },
    populateStudentList() {
      if (this.studentList.length) this.studentList = [];
      let s = this.organizationStudentsObject;
      for (let student of Object.keys(s)) {
        this.studentList.push({
          id: student,
          name: s[student].name,
          avatar: this.avatarInitials(s[student].name),
          color: this.randomColour(),
          email: s[student].email,
          number: s[student].phone,
          add: false,
          latest: false
        });
      }
    },

    handleCancel() {
      for (let student of this.studentList) {
        student.add = student.latest;
      }
      this.addMode = false;
    },

    save() {
      for (let student of this.studentList) {
        student.latest = student.add;
      }
      this.$emit("bulk_add", this.addList);
      this.$root.$emit("alert", ["Success", "Changes successfull"]);
      this.addMode = false;
    }
  }
};
</script>

<style scoped lang="scss">
.table {
  background-color: white;

  @media screen and (min-width: 1601px) {
    width: 1400px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    width: 975px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    width: 495px;
  }

  @media screen and (max-width: 599px) {
    width: 275px;
  }
}
.header {
  /* border: 1px solid #8b8b8b7f; */
  border: 0px solid #00000000;
  border-radius: 10px 10px 0px 0px;
  z-index: 1;

  @media screen and (min-width: 1601px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 599px) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.content {
  max-height: 60vh;
  border: 2px solid #00000000;
  border-top-width: 0px;
  border-radius: 0px 0px 10px 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.controls {
  border: 2px solid #00000000;
  border-top-width: 0px;
  border-radius: 0px 0px 10px 10px;
  padding: 30px 10px 20px 10px;
}

.content-item {
  border-style: solid;
  border-color: #8b8b8b7f;
  border-width: 0px 0px 1px 0px;

  @media screen and (min-width: 1601px) {
    padding: 0px 8px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    padding: 0px 5px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    padding: 0px 5px;
  }

  @media screen and (max-width: 599px) {
    padding: 0px 3px;
  }
}

.content-text {
  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 10px;
  }
}

.btn-viz {
  visibility: none;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
