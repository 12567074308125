var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c("div", { staticClass: "table" }, [
        _c(
          "div",
          { staticClass: "header px-3 elevation-3" },
          [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "2" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "d-flex align-center justify-center" },
                          [
                            _c("span", { staticClass: "content-text" }, [
                              _vm._v(_vm._s(!_vm.$language ? "Avatar" : "ছবি")),
                            ]),
                          ]
                        ),
                        _vm.screenSize >= 900
                          ? _c("v-col", { attrs: { cols: "3" } })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-col", { staticClass: "d-flex align-center" }, [
                  _c("span", { staticClass: "content-text" }, [
                    _vm._v(_vm._s(!_vm.$language ? "Name" : "নাম")),
                  ]),
                ]),
                _c("v-col", { staticClass: "d-flex align-center" }, [
                  _c("span", { staticClass: "content-text" }, [
                    _vm._v(
                      _vm._s(!_vm.$language ? "Number" : "মোবাইল নাম্বার")
                    ),
                  ]),
                ]),
                _vm.screenSize >= 900
                  ? _c("v-col", { staticClass: "d-flex align-center" }, [
                      _c("span", { staticClass: "content-text" }, [
                        _vm._v(_vm._s(!_vm.$language ? "Email" : "ইমেইল")),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "v-col",
                  { attrs: { cols: "2" } },
                  [
                    _c(
                      "v-row",
                      [
                        _vm.screenSize >= 900
                          ? _c("v-col", { attrs: { cols: "2" } })
                          : _vm._e(),
                        _c(
                          "v-col",
                          { staticClass: "d-flex align-center justify-center" },
                          [
                            _c("span", { staticClass: "content-text" }, [
                              _vm._v(
                                _vm._s(
                                  !_vm.$language ? "Add/Remove" : "সংযোগ/অপসারণ"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content px-4 pt-2" },
          _vm._l(_vm.studentList, function (student, index) {
            return _c(
              "v-row",
              {
                key: index,
                staticClass: "content-item",
                attrs: { "no-gutters": "" },
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "2" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "d-flex align-center justify-center" },
                          [
                            _c(
                              "v-avatar",
                              {
                                staticClass: "white--text",
                                attrs: {
                                  color: student.color,
                                  size: _vm.avatarSize,
                                },
                              },
                              [
                                _c("span", { staticClass: "content-text" }, [
                                  _vm._v(_vm._s(student.avatar)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.screenSize >= 900
                          ? _c("v-col", { attrs: { cols: "3" } })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-col", { staticClass: "d-flex align-center" }, [
                  _c("span", { staticClass: "content-text text-truncate" }, [
                    _vm._v(_vm._s(student.name)),
                  ]),
                ]),
                _c("v-col", { staticClass: "d-flex align-center" }, [
                  _c("span", { staticClass: "content-text text-truncate" }, [
                    _vm._v(_vm._s(student.number)),
                  ]),
                ]),
                _vm.screenSize >= 900
                  ? _c("v-col", { staticClass: "d-flex align-center" }, [
                      _c("span", { staticClass: "content-text" }, [
                        _vm._v(_vm._s(student.email)),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "v-col",
                  { attrs: { cols: "2" } },
                  [
                    _c(
                      "v-row",
                      [
                        _vm.screenSize >= 900
                          ? _c("v-col", { attrs: { cols: "2" } })
                          : _vm._e(),
                        _c(
                          "v-col",
                          { staticClass: "d-flex align-center justify-center" },
                          [
                            _c("mk-check-box", {
                              attrs: {
                                color: "#fc6222",
                                active: student.add,
                                index: index,
                                "size-object": _vm.checkDim,
                                "min-width": "12",
                              },
                              on: { click: _vm.mark },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "controls" },
          [
            _c(
              "transition",
              [
                _c(
                  "v-row",
                  { staticClass: "px-3" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "d-flex justify-end pr-5" },
                      [
                        _vm.addMode
                          ? _c(
                              "v-btn",
                              {
                                class: [
                                  _vm.screenSize > 600 ? "mr-5" : "mr-2 ",
                                  "rounded-lg",
                                ],
                                attrs: {
                                  "x-large": _vm.screenSize >= 1600,
                                  large:
                                    _vm.screenSize >= 900 &&
                                    _vm.screenSize < 1600,
                                  "x-small": _vm.screenSize < 600,
                                  outlined: "",
                                  color: "red accented-3",
                                },
                                on: { click: _vm.handleCancel },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "custom-font" },
                                  [
                                    _vm.screenSize > 600
                                      ? _c("v-icon", [_vm._v("cancel")])
                                      : _vm._e(),
                                    _vm._v(
                                      _vm._s(
                                        !_vm.$language ? "Cancel" : "বাতিল করুন"
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-btn",
                          {
                            class: [
                              "white--text rounded-lg",
                              _vm.screenSize > 600 ? "mr-5 px-10" : "",
                            ],
                            attrs: {
                              loading: _vm.saving["status"],
                              depressed: "",
                              "x-large": _vm.screenSize >= 1600,
                              large:
                                _vm.screenSize >= 900 && _vm.screenSize < 1600,
                              "x-small": _vm.screenSize < 600,
                              color: "#0099dd",
                            },
                            on: { click: _vm.save },
                          },
                          [
                            _c("span", { staticClass: "custom-font" }, [
                              _vm._v(
                                _vm._s(
                                  !_vm.$language ? "Save Changes" : "সেভ করুন"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }