<template>
  <v-container>
    <PageTitle
      v-if="displayTitle"
      previous-location
      :page-title="!$language ? 'Batches' : 'ব্যাচ সমূহ'"
      class="mt-5"
    />
    <batch-create-c
      :course-id="courseId"
      @tab-switch="tabSwitchHandler"
    ></batch-create-c>
  </v-container>
</template>

<script>
import BatchCreate from "#ecf/batch/components/BatchCreate";
import PageTitle from "~ecf/components/PageTitle";

export default {
  name: "BatchCreatePage",
  components: { BatchCreateC: BatchCreate, PageTitle },
  props: {
    courseId: String
  },
  data() {
    return {
      displayTitle: true
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    tabSwitchHandler() {
      this.displayTitle = !this.displayTitle;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    addClass(day) {
      this.formData.schedule[day].push(
        `${this.start_time[day]}-${this.end_time[day]}`
      );
      this.hideClassPicker(day);
    },
    clearClass(day) {
      this.formData.schedule[day] = [];
    },
    showClassPicker(day) {
      // classData.days.forEach(d => {
      //   this.formData.schedule[d].push(
      //     `${classData.startTime}-${classData.endTime}`
      //   );
      // });
      // this.showClassPicker = false;
      this.$set(this.classPicker, day, true);
      this.$set(this.start_time, day, undefined);
      this.$set(this.end_time, day, undefined);
    },
    hideClassPicker(day) {
      // classData.days.forEach(d => {
      //   this.formData.schedule[d].push(
      //     `${classData.startTime}-${classData.endTime}`
      //   );
      // });
      // this.showClassPicker = false;
      this.$set(this.classPicker, day, false);
    },
    async save() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        this.saving = true;
        await this.$store.dispatch("batch/create", {
          courseId: this.courseId,
          batchData: this.batch
        });
        this.$router.go(-1);
      } catch (e) {
        this.$root.$emit("alert", ["Alert", e.message])
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>
