<template>
  <v-container class="mt-2">
    <div v-if="tab == 1" class="mb-5">
      <v-btn class="mr-2 mb-2" color="black" icon large @click="tabSwitch()">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <span class="fake-title">{{
        !$language ? "Batch Create" : "ব্যাচ তৈরী করুন"
      }}</span>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item class="clear-background-item">
        <submission-alert-message
          ref="banner"
          class="py-3"
          v-model="showFormAlertMessage"
          :message="alertMessage"
          style="max-width: 1100px"
          should-scroll-to-top
        ></submission-alert-message>
        <v-card class="m-light-card">
          <batch-form ref="form" v-model="formData" :all-valid.sync="ok"></batch-form>
          <v-row :class="[formHorizPad, 'mt-6']">
            <v-col class="d-flex justify-start">
              <h2 class="custom-font enroll-header">
                {{ !$language ? "Bulk Enrollment" : "বাল্ক এনরোলমেন্ট" }}
              </h2>
            </v-col>
          </v-row>
          <v-row :class="formHorizPad">
            <v-col class="d-flex justify-start">
              <v-btn outlined color="#0099dc" @click="tabSwitch()">{{
                !$language ? "Add student" : "শিক্ষার্থী যোগ করুন"
              }}</v-btn>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row class="px-3">
              <v-col class="d-flex justify-end pr-5">
                <v-btn
                  depressed
                  :x-large="screenSize >= 1600"
                  :large="screenSize >= 900 && screenSize < 1600"
                  :small="screenSize < 600"
                  outlined
                  color="red accented-3"
                  :class="{ 'mr-5': screenSize >= 600 }"
                  @click="$router.replace({ name: 'batches' })"
                >
                  <v-icon v-if="screenSize >= 600">cancel</v-icon>
                  <span class="custom-font">{{
                    !$language ? "Cancel" : "বাতিল করুন"
                  }}</span>
                </v-btn>
                <v-btn
                  :x-large="screenSize >= 1600"
                  :large="screenSize >= 900 && screenSize < 1600"
                  :small="screenSize < 600"
                  color="#0099dd"
                  :class="[
                    'white--text elevation-3',
                    screenSize >= 600 ? 'px-10 ml-5' : 'ml-2',
                  ]"
                  @click="save"
                >
                  <span class="custom-font">{{
                    !$language ? "Create" : "তৈরী করুন"
                  }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item class="clear-background-item">
        <bulk-enrollment @bulk_add="handleBulkAdd"></bulk-enrollment>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import BatchForm from "#ecf/batch/components/BatchForm";
import BulkEnrollment from "#ecf/batch/components/BulkEnrollment";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage.vue";

export default {
  name: "BatchCreate",
  components: { BatchForm, BulkEnrollment, SubmissionAlertMessage },
  props: {
    courseId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      tab: 0,
      ok: true,
      formData: {
        summary: undefined,
        description: undefined,
        batch_type: undefined,
        payment_type: undefined,
        price: 0,
        title: undefined,
        begin_date: undefined,
        end_date: undefined,
        teacher_id: undefined,
        schedule: {
          Saturday: undefined,
          Sunday: undefined,
          Monday: undefined,
          Tuesday: undefined,
          Wednesday: undefined,
          Thursday: undefined,
          Friday: undefined,
        },
      },
      bulkEnrollList: {},
      saving: false,
      showFormAlertMessage: false,
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    formHorizPad() {
      return this.screenSize < 600
        ? "px-4"
        : this.screenSize < 900
        ? "px-7"
        : this.screenSize < 1600
        ? "px-11"
        : "px-15";
    },
    alertMessage() {
      return this.$language
        ? "সকল বাধ্যতা মূলক তথ্য প্রদান করুন"
        : "Please fill out all mandatory fields";
    },
  },
  watch: {
    ok(value){
      if(value) this.showFormAlertMessage = false;
    }
  },
  created() {},
  methods: {
    handleBulkAdd(payload) {
      this.bulkEnrollList = payload;
    },
    tabSwitch() {
      this.tab = this.tab ^ 1;
      this.$emit("tab-switch");
    },
    async save() {
      if (!this.ok) {
        await this.$refs.form.validate();
        if (!this.showFormAlertMessage) this.showFormAlertMessage = true;
        else {
          await this.$refs.banner.flash();
        }
        return;
      }
      if (this.saving) return;
      try {
        this.saving = true;
        let info = await this.$store.dispatch("batch/create", {
          courseId: this.courseId,
          batchData: this.formData,
        });
        if (this.bulkEnrollList) {
          await this.bullkAddStudents(info["batch_id"]);
        }
        this.$router.replace({ name: "batches" });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.saving = false;
      }
    },
    async bullkAddStudents(batchId) {
      await this.$store.dispatch("batch/updateStudentList", {
        partner_code: this.$route.params.partner_code,
        course_id: this.courseId,
        batch_id: batchId,
        data: Object.keys(this.bulkEnrollList),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-font {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.clear-background-item {
  background-color: #f3f7ff;
}

.enroll-header {
  @media screen and (min-width: 1601px) {
    font-size: 28px;
  }

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    font-size: 22px;
  }

  @media screen and (max-width: 599px) {
    font-size: 15px;
  }
}

.fake-title {
  font-family: "Poppins", "Helvetica", sans-serif !important;
  font-weight: 700 !important;
  font-size: 1.5rem;
}
</style>
